import { useCallback, useEffect, useState } from 'react';

import { gql, useMutation } from '@apollo/client';
import axios from 'axios';
import { useRouter } from 'next/router';
import { checkoutApi } from 'ys-analytics';

import { useApollo } from '../lib/apolloClient';
import { useCartStore } from '../store/cartStore';

import { isCheckoutRelatedPage } from './checkout';
import { cookieService } from './CookieService';

const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_CENTRA_API_URL,
  headers: {},
});

export const SUCCESS_PAGE = '/api/payment-return/selection/receipt';
export const FAILURE_PAGE = '/selection/failed';

export const useRevalidateCart = () => {
  const client = useApollo();

  const revalidateCart = useCallback(async () => {
    await client.refetchQueries({
      include: ['GetMniCartData', 'GetCartData', 'CheckoutPageQuery'],
    });
  }, [client]);

  return revalidateCart;
};

export const useAddToCart = () => {
  const token = cookieService.getToken();
  const { setCartOpen } = useCartStore();
  const router = useRouter();
  const isSelectionPath = isCheckoutRelatedPage(router.pathname);
  const revalidateCart = useRevalidateCart();

  const handleAddToCart = useCallback(
    async (item: string, quantity: number) => {
      await axiosInstance.post(
        `/items/${item}/quantity/${quantity}`,
        {
          quantity,
          productUrl: window.location.href,
        },
        {
          headers: {
            ...(token && { 'api-token': token }),
          },
        },
      );

      await revalidateCart();

      if (!isSelectionPath) {
        setCartOpen(true);
      }
    },
    [revalidateCart, token, setCartOpen, isSelectionPath],
  );

  return handleAddToCart;
};

export const useRemoveFromCart = () => {
  const token = cookieService.getToken();
  const { setCartOpen } = useCartStore();
  const router = useRouter();
  const isSelectionPath = isCheckoutRelatedPage(router.pathname);
  const revalidateCart = useRevalidateCart();

  const handleRemoveFromCart = useCallback(
    async (item: string, quantity: number) => {
      await axiosInstance.delete(`/lines/${item}/quantity/${quantity}`, {
        headers: {
          ...(token && { 'api-token': token }),
        },
      });

      await revalidateCart();

      if (!isSelectionPath) {
        setCartOpen(true);
      }
    },
    [revalidateCart, token, setCartOpen, isSelectionPath],
  );

  return handleRemoveFromCart;
};

export const useAddVoucher = () => {
  const token = cookieService.getToken();
  const revalidateCart = useRevalidateCart();

  const handleAddVoucher = useCallback(
    async (voucher: string) => {
      await axiosInstance.post(
        `/vouchers`,
        {
          voucher,
        },
        {
          headers: {
            ...(token && { 'api-token': token }),
          },
        },
      );

      await revalidateCart();
    },
    [revalidateCart, token],
  );

  return handleAddVoucher;
};

export const useRemoveVoucher = () => {
  const token = cookieService.getToken();
  const revalidateCart = useRevalidateCart();

  const handleRemoveVoucher = useCallback(
    async (voucher: string) => {
      await axiosInstance.delete(`/vouchers`, {
        headers: {
          ...(token && { 'api-token': token }),
        },
        data: { voucher },
      });

      await revalidateCart();
    },
    [revalidateCart, token],
  );

  return handleRemoveVoucher;
};

export const useChangePaymentMethod = () => {
  const token = cookieService.getToken();
  const revalidateCart = useRevalidateCart();

  const handleChangePaymentMethod = useCallback(
    async (paymentMethod: string) => {
      await axiosInstance.put(`/payment-methods/${paymentMethod}`, undefined, {
        headers: {
          ...(token && { 'api-token': token }),
        },
      });

      await revalidateCart();
    },
    [revalidateCart, token],
  );

  return handleChangePaymentMethod;
};

export const useChangeShippingMethod = () => {
  const token = cookieService.getToken();
  const revalidateCart = useRevalidateCart();

  const handleChangeShippingMethod = useCallback(
    async (data: any) => {
      await axiosInstance.put(`/payment-fields`, data, {
        headers: {
          ...(token && { 'api-token': token }),
        },
      });

      await revalidateCart();
    },
    [revalidateCart, token],
  );

  return handleChangeShippingMethod;
};

export const UPDATE_COUNTRY_AND_LANGUAGE_MUTATION = gql`
  mutation UpdateCountryAndLanguageMutation($country: String!, $language: String!) {
    changeCountry(country: $country, language: $language) {
      location {
        country
        name
        state
        stateName
        eu
        shipTo
        language
      }
    }
  }
`;

export const useChangeCountryAndState = () => {
  const token = cookieService.getToken();
  const revalidateCart = useRevalidateCart();
  const [updateCountryAndLanguage] = useMutation(UPDATE_COUNTRY_AND_LANGUAGE_MUTATION);

  const handleChangeShippingMethod = useCallback(
    async (country: string, state?: string) => {
      await updateCountryAndLanguage({
        variables: {
          country,
          language: '',
        },
      });

      await axiosInstance.put(`/countries/${country}${state ? `/states/${state}` : ''}`, undefined, {
        headers: {
          ...(token && { 'api-token': token }),
        },
      });

      await revalidateCart();
    },
    [revalidateCart, token, updateCountryAndLanguage],
  );

  return handleChangeShippingMethod;
};

export const usePaymentInit = () => {
  const token = cookieService.getToken();
  const router = useRouter();
  const [form, setForm] = useState<string>(null);

  const handleResetForm = useCallback(() => {
    setForm(null);
  }, []);

  const handlePaymentInit = useCallback(
    async (formData: any) => {
      handleResetForm();

      const { data } = await axiosInstance.post(`/payment`, formData, {
        headers: {
          ...(token && { 'api-token': token }),
        },
      });

      switch (data.action) {
        case 'redirect':
          if (data.url) {
            window.location.href = data.url;
          }
          break;
        case 'form':
          setForm(data.formHtml);
          break;
        case 'success':
          router.push(SUCCESS_PAGE);
          break;
        case 'external': {
          const externalResponse = await axios.post(
            '/api/coingate-payment/initialize',
            { selectionId: data.selection },
            {
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
              },
            },
          );

          window.location.href = externalResponse.data.paymentUrl;
        }
      }
    },
    [handleResetForm, router, token],
  );

  return [handlePaymentInit, form, handleResetForm] as const;
};

export const usePaymentResult = () => {
  const token = cookieService.getToken();

  const handlePaymentResult = useCallback(async () => {
    try {
      const { data } = await axiosInstance.post(
        `/payment-result`,
        {},
        {
          headers: {
            ...(token && { 'api-token': token }),
          },
        },
      );

      checkoutApi.eePurchase(data.order);
      checkoutApi.ga4eePurchase(data.order);
      checkoutApi.fbPurchase(data.order);
    } catch (_) {
      // Swallow error to prevent 404, because this function is triggered twice during rendering the page
    }
  }, [token]);

  useEffect(() => {
    handlePaymentResult();
  }, [handlePaymentResult]);
};
